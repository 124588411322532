'use strict';

angular.module('enervexSalesappApp').service('DocumentsService', function(Document, DocumentComment, Attachment, $stateParams){
	this.getDocument = getDocument;
	this.getDocuments = getDocuments;
	this.saveNewDocument = saveNewDocument;
	this.saveDocument = saveDocument;
	this.removeDocument = removeDocument;
	this.fetchDocumentVersions = fetchDocumentVersions;

	this.createAttachment = createAttachment;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;

	function createAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}
	function getComments() {
		return DocumentComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			documentId: $stateParams.documentId,
		});
	}
	function createComment(newComment) {
		return DocumentComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			documentId: $stateParams.documentId
		}, newComment);
	}
	function removeComment(comment) {
		return DocumentComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			documentId: $stateParams.documentId,
			id: comment._id
		});
	}
	function updateComment(comment) {
		return DocumentComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			documentId: $stateParams.documentId,
			id: comment._id
		}, comment);
	}
	function getDocument(){
		if ($stateParams.version) {
			return Document.version({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.documentId,
				version: $stateParams.version
			});
		} else {
			return Document.get({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.documentId
			});
		}
	}
	function getDocuments(){
		return Document.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}
	function fetchDocumentVersions() {
		return Document.versions({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.documentId
		});
	}
	function saveNewDocument(doc) {
		return Document.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
		}, doc);
	}
	function saveDocument(doc) {
		return Document.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: doc._id
		}, doc);
	}
	function removeDocument(doc) {
		return Document.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: doc._id
		});
	}
});
